<template>
	<div class="product-resume">
		<div class="table-container">
			<div class="title">
				<span class="icon1">
					<i :class="menuIcon"></i>
				</span>
				<span class="txt">{{menuName}}</span>
			</div>
			<el-table
				class="table"
				ref="table"
				:data="tableData"
				height="250"
				:row-class-name="tableRowClassName"
				:height="tableHeight"
				style="width: 100%;"
			>
				<el-table-column label="日期时间" prop="dates" min-width="12.5%" align='center'></el-table-column>
				<el-table-column label="工位	" prop="stationName" min-width="12.5%" align='center'></el-table-column>
				<el-table-column label="作业员" prop="userName" min-width="12.5%" align='center'></el-table-column>
				<el-table-column label="工单号" prop="workOrderNo" min-width="12.5%" align='center'></el-table-column>
				<el-table-column label="工序名称" prop="procedureName" min-width="12%" align='center'></el-table-column>
				<el-table-column label="开始时间" prop="createTime" min-width="12%" align='center'></el-table-column>
				<el-table-column label="结束时间	" prop="endTime" min-width="12%" align='center'></el-table-column>
				<el-table-column label="生产时长(小时)" prop="duration" min-width="15%" align='center'>
				</el-table-column>
			</el-table>
		</div>
		<div class="expand-container">
			<div class="title">
				<span class="icon2">
					<i class="iconfont icon-richscan_icon"></i>
				</span>
				<span class="txt">扫码/刷卡</span>
			</div>
			<el-form label-position="left" label-width="100px" @submit.native.prevent style="padding: 0.625rem;">
				<el-form-item label="刷卡" class="remain-form-box">
					<el-input v-model="cardNo" @keyup.enter.native="getCardNo($event)" clearable></el-input>
				</el-form-item>
				<el-form-item label="产品SN" class="remain-form-box">
					<el-input v-model="productionSn" @keyup.enter.native="getProductSN($event)" clearable></el-input>
				</el-form-item>
				<el-form-item label="物料SN" class="remain-form-box">
					<el-input v-model="materialSn" @keyup.enter.native="getMaterialSN($event)" clearable></el-input>
				</el-form-item>
				<!-- <el-form-item label="操作员" class="remain-form-box">
					<el-input v-model="operator" @keyup.enter.native="getOperator($event)" clearable></el-input>
				</el-form-item>
				<el-form-item label="工序" class="remain-form-box">
					<el-input v-model="process" @keyup.enter.native="getProcess($event)" clearable></el-input>
				</el-form-item> -->
				<el-form-item label="提示" class="remain-form-box">
					<el-input v-model="remain" class="remain-form-item" type="textarea" readonly rows="6" clearable></el-input>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import { 
		addProduct,
		checkCardType
	} from 'network/productResume.js'
	export default {
		name: "product-resume",
		data() {
			return {
				tableHeight: 0, //表格高度
				tableData: [],
				userId: sessionStorage.getItem("userId"),
				cardNo: "",
				productionSn: "",
				materialSn: "",
				operator: "",
				process: "",
				remain: "",
			}
		},
		props: {
			menuIcon: {
				type: String,
				default: ""
			},
			menuName: {
				type: String,
				default: ""
			}
		},
		mounted() {
			//100表示你想要调整的表格距离底部的高度（你可以自己随意调整）
			this.$nextTick(function () {
				this.tableHeight = window.innerHeight - 200;
				
				// 监听窗口大小变化
				let self = this;
				window.onresize = function() {
					self.tableHeight = window.innerHeight - 200;
				}
			})
		},
		created() {
			
		},
		methods: {
			//表格隔行变色
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 === 1) {
					return 'success-row';
				}
				return '';
			},
			addProduct1() {
				let obj = {
					operatorId: this.userId,
					productionSn: this.productionSn,
					stationId: 222,
					eqId: 103,
					type: 1,
				}
				addProduct(obj).then(res => {
					console.log(res)
					if(res.data.code == 0 ) {
						this.$message.success(res.data.msg)
						this.tableData = res.data.data
					}else {
						this.$message.error(res.data.msg)
					}
				})
			},
			//刷卡获取产品编码
			getCardNo(e) {
				if(e.target.value == '' || e.target.value == null) {
					return
				}
				let obj = {
					eqId: 103,
					card: this.cardNo
				}
				checkCardType(obj).then(res => {
					// console.log(res)
					if(res.data.code == 0) {
						// console.log(res)
						if(res.data.data.cardField == "productionSn") {
							this.productionSn = res.data.data.card
						}else if(res.data.data.cardField == "materialSn") {
							this.materialSn = res.data.data.card
						}
						this.cardNo = ""
					}
				})
			},
			getProductSN(e) {
				if(e.target.value == '' || e.target.value == null)
				return
				this.productSN = e.target.value;
				this.remain += "产品SN: " + e.target.value + ";" + "\n";
				this.addProduct1()
			},
			getMaterialSN(e) {
				if(e.target.value == '' || e.target.value == null)
				return
				this.remain += "物料SN: " + e.target.value + ";" + "\n";
			},
			getWorkOrderNo(e) {
				if(e.target.value == '' || e.target.value == null)
				return
				this.remain += "工单号: " + e.target.value + ";" + "\n";
			},
			getOperator(e) {
				if(e.target.value == '' || e.target.value == null)
				return
				this.remain += "操作员: " + e.target.value + ";" + "\n";
			},
			getProcess(e) {
				if(e.target.value == '' || e.target.value == null)
				return
				this.remain += "工序: " + e.target.value + ";" + "\n";
			}
		}
	}
</script>

<style>
/* 表格隔行变色颜色 */
.el-table .success-row {
	background: rgba(244,246,247,1);
}
/*表格字体*/
.el-table {
	font-size: 1.375rem !important;
}
/*表头样式*/
.el-table th {
	background: rgba(245,247,247,1) !important;
	font-size: 1.375rem !important;
	font-family: PingFang SC !important;
	font-weight: 500 !important;
	color: rgba(58,132,171,1) !important;
}
.el-table td, .el-table th {
	height: 3.75rem !important;
	padding: 0 !important;
}
.el-table .cell {
	padding: 0.625rem 0 !important;
}
</style>

<style lang="scss" scoped>
$mainBgc: #F4F5FA;
.product-resume {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	border-radius: 0.625rem;
	background-color: $mainBgc;
	.title {
		width: 100%;
		display: flex;
		padding: 0.625rem;
		.icon1, .icon2 {
			display: inline-block;
			width: 2.5rem;
			height: 2.5rem;
			text-align: center;
			line-height: 2.5rem;
			border-radius: 0.625rem;
			margin-right: 1.25rem;
			background:linear-gradient(0deg, rgba(41,187,192,1) 0%, rgba(73,208,206,1) 100%);
			i {
				font-size: 1.375rem;
				font-weight: 500;
				color: white;
			}
		}
		.icon2 {
			background: linear-gradient(0deg,rgba(255,125,29,1) 0%,rgba(255,189,32,1) 100%);
		}
		.txt {
			height: 2.5rem;
			font-size: 1.625rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(58,132,171,1);
			line-height: 2.5rem;
			letter-spacing: 3px;
		}
	}
	.table-container {
		width: 74.37%;
		height: 100%;
		border-radius: 0.625rem;
		position: relative;
		box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
		background: white;
	}
	.expand-container {
		width: 25.5%;
		height: 100%;
		background: white;
		border-radius: 0.625rem;
		box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
		margin-left: 1.75rem;
		overflow-x: hidden;
		overflow-y: scroll;
	}
}	
</style>
