import instance from './request'

//根据卡号查询产品SN，物料SN
export function checkCardType(dataObj) {
  return instance({
    url: 'production/cardType',//请求方式',
    method: 'POST',
    params: dataObj
  })
}

//添加产品履历
export function addProduct(dataObj) {
  return instance({
    url: 'production/product/add',//请求方式',
    method: 'POST',
    params: dataObj
  })
}